<template>
    <div class="main2">
        <!-- <iframe class="mobile-iframe" id="ifm" :src="liveUrl" style="width: 100%; height: 100%; overflow:hidden" scrolling="yes" allowFullScreen /> -->
    </div>
</template>
<script>
import router from '../../router';
import { Field, Button, Toast } from 'vant';
import Vue from 'vue';
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
    components: {},
    data() {
        return {
            contactId: '25',
            appid: '',
            pharmacyStoreCustomerId: '',
            pharmacyId: '1',
            storeId: '1',
            puserCode: '',
            liveDetail: {},
            contactInfo: {},
            liveUrl: 'https://mudu.tv/live/watch/l860njvm'
        };
    },

    created() {
        console.log('route data', this.$route.query);
        // this.contactId = this.$route.query.contactId;
        // this.appid = this.$route.query.appid;
        this.appid = 'wx19bda6338e5e3f04';
        this.pharmacyStoreCustomerId = this.$route.query.pharmacyStoreCustomerId;
        this.puserCode = this.$route.query.puserCode;
        this.code = this.$route.query.code;
        // localStorage.setItem(
        //     `token_${this.appid}`,
        //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwaGFybWFjeUlkIjoxLCJzdG9yZUlkIjoxLCJwdXNlckNvZGUiOiIyMDIzMTEyMjE2NDMxNjg5OTAwMDAwMDAwMjFwckkifQ.0NWB5bvyM6kKzFU2CLrbbvpVDCg-GH9SGx3sL5LMd1pS65e2vuIq5NOpseRbD8n3o31ZZLpDAO0YRs6Q5wGE02yQ3egx3P95LQPh49NVvbsontCSmDMxu3r6pzS3VvcMF788GpY3U-NlJFjWTlYd9Z53NqCDS9Co5vNcRbYXuoUHMw92_WtEaei9xP3peMyRDzCbGAuadV9ivVu1BsRifJeSaCM043Q3THaHOVlMwIHtQ_Wl1wrfpB97s22pSl1jUAmw776jC2SZXRZWvwC5y8ZsgwP_xLdiLJB3SN5tkEg2gKatjV8QwPM_hHVCksgdJe-gD0yngZmbtpJ1AUZsdw'
        // );
        this.getContactInfo();
        this.checkLoginState();
    },
    mounted() {
        if (this.contactInfo && this.puserCode) {
            this.visit();
        }
    },
    beforeDestroy() {
        if (this.visit != null) {
            this.watchEnd();
        }
    },
    methods: {
        jsSDK() {
            this.$axios
                .get(`/notice/weixin/util/get/js/config?appid=${this.appid}&url=${decodeURIComponent(window.location.href)}`, {})
                .then(res => {
                    console.log(res);
                    wx.config({
                        debug: false,
                        appId: res.data.appid,
                        timestamp: res.data.timestamp * 1,
                        nonceStr: res.data.nonceStr,
                        signature: res.data.signature,
                        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
                    });
                    wx.ready(() => {
                        console.log('wx.ready');
                        wx.onMenuShareAppMessage({
                            title: this.liveDetail.name || '',
                            desc: this.liveDetail.intro || '',
                            link: window.location.href,
                            imgUrl: this.liveDetail.cover,
                            success: function() {}
                        });
                        wx.onMenuShareTimeline({
                            title: this.liveDetail.name || '',
                            link: window.location.href,
                            imgUrl: this.liveDetail.cover,
                            success: function() {}
                        });
                    });

                    wx.error(function(err) {
                        console.log('wx.error', err);
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getParameterByName(name) {
            var url = window.location.search;
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },

        //公众号静默授权
        getOpenId() {
            // https://test-live.shulan.com/test-shoh5/?code=011nwH000qFuaR1xv9200Lg78j2nwH0C&state=LIVEOAUTH#/activity/live
            // const url = window.location.search;
            let code = this.getParameterByName('code');

            window.alert(code);
            if (code) {
                this.$axios
                    .post(`/multi/terminal/user/puser/login`, {
                        code,
                        pharmacyId: this.pharmacyId,
                        storeId: this.storeId,
                        appid: this.appid
                    })
                    .then(res => {
                        localStorage.setItem(`token_${this.appid}`, res.data.access_token);
                        this.checkPuserCode();
                    })
                    .catch(() => {
                        Toast.clear();
                    });
            } else {
                let url = encodeURIComponent(window.location.href);
                console.log('encodeURIComponent', url, window.location.href);
                let state = 'LIVEOAUTH';
                window.alert(code);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
            }
        },

        checkLoginState() {
            if (this.appid) {
                var token = localStorage.getItem(`token_${this.appid}`);
                if (!token) {
                    this.getOpenId();
                } else {
                    this.checkPuserCode();
                }
            }
        },

        getContactInfo() {
            this.$axios
                .get(`/user/customer/contact/info?contactId=${this.contactId}`)
                .then(res => {
                    this.contactInfo = res.data;

                    this.getLiveDetail();
                })
                .catch(err => {
                    Toast.clear();
                });
        },

        getLiveDetail() {
            this.$axios
                .get(`/rightsInterest/live/activity/user/get/liveInfo?id=${this.contactInfo.resourceId}`)
                .then(res => {
                    this.liveDetail = res.data;
                    this.jsSDK();
                })
                .catch(() => {
                    Toast.clear();
                });
        },

        checkPuserCode() {
            if (this.puserCode == null || this.puserCode == '') {
                this.$axios
                    .get(`/puser/get/puserInfo`, {
                        tokenSource: this.appid
                    })
                    .then(res => {
                        this.puserCode == res.data.puserCode;
                    })
                    .catch(err => {
                        Toast.clear();
                    });
            } else {
                this.createContactVisit();
            }
        },

        //创建活动记录
        createContactVisit() {
            this.$axios
                .post('/user/customer/contact/puser/create/visit', {
                    contactId: this.contactId,
                    puserCode: this.puserCode,
                    pharmacyStoreCustomerId: this.pharmacyStoreCustomerId || ''
                })
                .then(res => {
                    this.visit();
                });
        },

        //创建访问记录，获取visitId
        visit() {
            this.$axios
                .post('/audioVideo/platformVideo/visit', {
                    userId: this.puserCode,
                    userType: 'officeAccountUser',
                    videoId: this.contactInfo.resourceId,
                    videoType: 2,
                    pharmacyId: this.pharmacyId,
                    storeId: this.storeId
                })
                .then(res => {
                    this.visitId = res.data.visitId || '';
                    if (this.visitId) {
                        this.watching();
                    }
                });
        },

        watching() {
            this.$axios
                .post('/audio/video/heartBeat/handle', {
                    visitId: this.visitId,
                    type: 'WATCHING',
                    videoSource: 'OWN_PLATFORM'
                })
                .then(res => {
                    this.setTimeout = setTimeout(() => {
                        this.watching();
                    }, 5000);
                });
        },
        watchEnd() {
            clearTimeout(this.setTimeout);
            if (this.visit != null) {
                this.visit == null;
                this.$axios
                    .post('/audio/video/heartBeat/handle', {
                        visitId: this.visitId,
                        type: 'WATCH_END',
                        videoSource: 'OWN_PLATFORM'
                    })
                    .then(res => {});
            }
        }
    }
};
</script>

<style lang="scss">
@function fit($num) {
    @return $num / 3;
}
#app {
    height: 100%;
}
.main2 {
    // background: rgb(24, 24, 24);
    width: 100%;
    height: 100%;
}
.status-bar {
    background: #29a3dc;
    width: 100%;
    height: fit(80px);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: fit(30px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: fit(34px);
        height: fit(34px);
        margin-left: fit(8px);
    }
}

.status-bar-mobile {
    background: #12beae;
    width: 100%;
    height: 88px;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 34px;
        height: 34px;
        margin-left: 8px;
    }
}
.mobile-iframe {
    // margin-top: 88px;
}
</style>
